import * as actionTypes from "./actionTypes";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import {
    getExtraHoursAuthorizationAPI,
    setExtraHoursAuthorizationAPI,
    downloadImportStructureExtraHoursAuthorizationAPI,
    importStructureExtraHoursAuthorizationAPI,
} from "@icarius-connection/api";
import { downloadBlob } from "@icarius-utils/download";
import { getFileExtension, getFileName } from "@icarius-utils/fileUpload";
import { getLocalizedErrorString } from "@icarius-localization/strings";

export const getExtraHoursAuthorizationFromServer = (from, to) => async (dispatch) => {
    dispatch({ type: actionTypes.GET_ROWS });
    try {
        let response = await getExtraHoursAuthorizationAPI(from && to ? `?from=${from}&to=${to}` : "");
        const { colaborators, currency_localization, date_format, selects, societyPeople, timeCalendars, unAuthArray } = response.data;
        let rows = response.data.data;

        // Me fijo aquellos que no tengo en unAuthArray
        let unAuthNotIncluded = unAuthArray.filter(el => !rows.some(row => row["Código de empleado"] === el["resource"] && row["Fecha"] === el["Fecha"]));
        unAuthNotIncluded.forEach(el => {
            const colaboratorData = colaborators.find(colaborator => colaborator["CODIGO DE EMPLEADO"] === el.resource);
            if (colaboratorData) {
                rows.push(
                    {
                        "Código de empleado": colaboratorData["CODIGO DE EMPLEADO"],
                        "Apellido y nombres": colaboratorData["APELLIDO Y NOMBRE"],
                        "Fecha": el["Fecha"],
                        "Hrs. Extras Realizadas": el.totalHhmm,
                        "Hrs. Extras Sin Autorizar": el.hhmm
                    }
                )
            }
        })
        dispatch({
            type: actionTypes.GET_ROWS_FULFILLED,
            payload: { colaborators, currency_localization, rows, date_format, selects, societyPeople, timeCalendars, unAuthArray },
        });
        return response;
    } catch (e) {
        dispatch({ type: actionTypes.GET_ROWS_REJECTED, payload: e });
        dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    }
};

export const setExtraHoursAuthorizationToServer = (data) => async (dispatch) => {
    dispatch({ type: actionTypes.GET_ROWS });
    try {
        let response = await setExtraHoursAuthorizationAPI(data);
        const { colaborators, currency_localization, date_format, selects, societyPeople, timeCalendars, unAuthArray } = response.data;
        let rows = response.data.data;

        // Me fijo aquellos que no tengo en unAuthArray
        let unAuthNotIncluded = unAuthArray.filter(el => !rows.some(row => row["Código de empleado"] === el["resource"] && row["Fecha"] === el["Fecha"]));
        unAuthNotIncluded.forEach(el => {
            const colaboratorData = colaborators.find(colaborator => colaborator["CODIGO DE EMPLEADO"] === el.resource);
            if (colaboratorData) {
                rows.push(
                    {
                        "Código de empleado": colaboratorData["CODIGO DE EMPLEADO"],
                        "Apellido y nombres": colaboratorData["APELLIDO Y NOMBRE"],
                        "Fecha": el["Fecha"],
                        "Hrs. Extras Realizadas": el.totalHhmm,
                        "Hrs. Extras Sin Autorizar": el.hhmm
                    }
                )
            }
        })
        dispatch({
            type: actionTypes.GET_ROWS_FULFILLED,
            payload: { colaborators, currency_localization, rows: response.data.data, date_format, selects, societyPeople, timeCalendars, unAuthArray },
        });
        dispatch(openSnackbarAction({ msg: 'Valores actualizados con éxito', severity: "success" }));
        return response;
    } catch (e) {
        dispatch({ type: actionTypes.GET_ROWS_REJECTED, payload: e });
        if (e.response.data.status === 'DUPLICATED_DATA') {
            dispatch(openSnackbarAction({ msg: "No pueden existir valores con el mismo colaborador y fecha", severity: "error" }));
            return e;
        }
        dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    }
};

export const downloadImportStructureAction = (dataToSend) => async (dispatch) => {
    dispatch({ type: actionTypes.DOWNLOAD });
    try {
        let response = await downloadImportStructureExtraHoursAuthorizationAPI(dataToSend);
        let title = response.headers["x-content-namefile"];

        if (response.status !== 200) {
            dispatch({ type: actionTypes.DOWNLOAD_REJECTED });
            dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
            return;
        }

        dispatch({
            type: actionTypes.DOWNLOAD_FULFILLED,
        });

        downloadBlob(response.data, getFileExtension(title), getFileName(title));

        return response;
    } catch (e) {
        dispatch({ type: actionTypes.DOWNLOAD_REJECTED });
        dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    }
};

export const importSpreadsheetAction = (dataToSend) => async (dispatch) => {
    dispatch({ type: actionTypes.SET_ROWS });
    try {
        let formData = new FormData();
        formData.append('file', dataToSend.file);

        let response = await importStructureExtraHoursAuthorizationAPI(formData);
        let title = response.headers["x-content-namefile"];
        let hasErrors = title && title.includes("Errores-");
        if (title) {
            title = title.replace("Errores-", "");
        }
        if (response.status !== 200) {
            dispatch({ type: actionTypes.SET_ROWS_REJECTED });
            dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
            return;
        }

        dispatch({
            type: actionTypes.SET_ROWS_FULFILLED,
        });

        if (title) {
            downloadBlob(response.data, getFileExtension(title), getFileName(title));
            dispatch(openSnackbarAction({
                msg: 'Importación finalizada, verifique el archivo para ver el resultado',
                severity: !hasErrors ? "success" : "warning",
                duration: null
            }));
            return response;
        }
        return response;
    } catch (e) {
        dispatch({ type: actionTypes.SET_ROWS_REJECTED, payload: e });
        dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    }
};