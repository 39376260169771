import NAME from "./constants";

export const getSocietyPeople = store => store[NAME].societyPeople;
export const getColaborators = store => store[NAME].colaborators;
export const getDateFormat = store => store[NAME].dateFormat;
export const getCurrencyLocalization = store => store[NAME].currencyLocalization;
export const getLoading = store => store[NAME].loading;
export const getRows = store => store[NAME].rows;
export const getTimeCalendars = store => store[NAME].timeCalendars;
export const getSelects = store => store[NAME].selects;
export const getUnAuthArray = store => store[NAME].unAuthArray;