import paths from "@icarius-localization/paths";

export default [
    {
        pages: [paths.extraHoursTable],
        config: {
            headerName: "Código de hora extra",
            field: "code",
            isObligatory: true,
            maxLength: 8,
            minLength: 1,
        }
    },
    {
        pages: [paths.extraHoursTable],
        config: {
            headerName: "Nombre",
            field: "name",
            isObligatory: true,
            maxLength: 20,
            minLength: 1,
        }
    },
    {
        pages: [paths.extraHoursTable],
        config: {
            headerName: "Tipo de hora",
            field: "type",
            isObligatory: true,
            isSelect: true,
        }
    },
]