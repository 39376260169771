import * as actionTypes from "./actionTypes";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import {
    getExtraHoursTableAPI,
    setExtraHoursTableAPI,
    deleteExtraHoursTableAPI
} from "@icarius-connection/api";

export const getExtraHoursTableFromServer = () => async (dispatch) => {
    dispatch({ type: actionTypes.GET_ROWS });
    try {
        let response = await getExtraHoursTableAPI();
        let rows = response.data && response.data.data;
        let tableTypes = response.data && response.data.tableTypes;

        dispatch({
            type: actionTypes.GET_ROWS_FULFILLED,
            payload: { rows, tableTypes },
        });
        return response;
    } catch (e) {
        dispatch({ type: actionTypes.GET_ROWS_REJECTED, payload: e });
        dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    }
};

export const setExtraHoursTableToServer = (data) => async (dispatch) => {
    dispatch({ type: actionTypes.SET_ROWS });
    try {
        let response = await setExtraHoursTableAPI({ data });
        let rows = response.data && response.data.data;

        dispatch({
            type: actionTypes.SET_ROWS_FULFILLED,
            payload: { rows },
        });
        dispatch(openSnackbarAction({ msg: 'Valores actualizados con éxito', severity: "success" }));
        return response;
    } catch (e) {
        dispatch({ type: actionTypes.SET_ROWS_REJECTED, payload: e });
        if (e.response.data.status === 'DUPLICATED_DATA') {
            dispatch(openSnackbarAction({ msg: "No pueden existir valores con el mismo Tipo, Código y Nombre", severity: "error" }));
            return e;
        }
        dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    }
};

export const deleteExtraHoursTableInServer = (codes) => async (dispatch) => {
    dispatch({ type: actionTypes.DELETE_ROWS });
    try {
        let response = await deleteExtraHoursTableAPI({ codes });
        let rows = response.data && response.data.data;

        dispatch({
            type: actionTypes.DELETE_ROWS_FULFILLED,
            payload: { rows },
        });

        return response;
    } catch (e) {
        dispatch({ type: actionTypes.DELETE_ROWS_REJECTED, payload: e });
        dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    }
};