import React, { useRef, useEffect, useState, useCallback } from "react";
import CommonPage from "@icarius-common/commonPage";
import EditableGridv2 from "@icarius-common/editableGrid/editableGridv2";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import paths from "@icarius-localization/paths";
import { getLocalizedString } from "@icarius-localization/strings";
import {
  getExtraHoursAuthorizationFromServer,
  setExtraHoursAuthorizationToServer,
  downloadImportStructureAction
} from "../actions";
import {
  getTimeCalendars,
  getLoading,
  getRows,
  getSocietyPeople,
  getColaborators,
  getCurrencyLocalization,
  getDateFormat,
  getUnAuthArray,
  getSelects,
} from "../selectors";
import { useDispatch, useSelector } from "react-redux";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import ConfirmationDialog from "./confirmationDialog";
import {
  getAppColor,
} from "src/app/selectors";
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { exportDataGrid } from "devextreme/excel_exporter"
import { InputLabel, Button, Select, FormControl, Grid, MenuItem, Typography } from "@material-ui/core";
import SecondGridDialog from "./secondGridDialog";
import { AddIcon, FileCopyIcon } from "@icarius-icons/";
import { formatDate } from "@icarius-utils/date";
import FiltersDialog from "./filtersDialog";
import moment from "moment";
import { openDialogAction } from "@icarius-common/dialog/actions";
import UploadFileDialog from "./uploadFileDialog";
import UserSelectionDialogWithReplica from "./userSelectionDialogWithReplica";

const ExtraHoursAuthorization = ({ history }) => {
  const filtersDummyData = {
    "Horario": "",
    "Turno": "",
    "Lugar de trabajo": "",
    "Obra o Faena": "",
    "Gerencia": "",
    "Colaborador": "",
    "Solo horas extras": ""
  };

  const [userSelectionDialogIsOpen, setUserSelectionDialogIsOpen] = useState(false);
  const [filteredData, setFilteredData] = useState();
  const [filterData, setFilterData] = useState(filtersDummyData);
  const [shouldReloadGrid, setShouldReloadGrid] = useState(false);
  const [confirmationDialogIsOpen, setConfirmationDialogIsOpen] = useState(false);
  const [uploadFileDialogIsOpen, setUploadFileDialogIsOpen] = useState(false);
  const [gridReference, setGridReference] = useState(null);
  const [gridData, setGridData] = useState(null);
  const [secondGridDialogIsOpen, setSecondGridDialogIsOpen] = useState(false);
  const [filtersDialogIsOpen, setFiltersDialogIsOpen] = useState(false);
  const [rowDataToReplicate, setRowDataToReplicate] = useState(null);
  const [selectedCalendar, setSelectedCalendar] = useState("");
  const rowData = useSelector(getRows);
  const selects = useSelector(getSelects);
  const isLoading = useSelector(getLoading);
  const societyPeople = useSelector(getSocietyPeople);
  const colaborators = useSelector(getColaborators);
  const locale = useSelector(getCurrencyLocalization);
  const dateFormat = useSelector(getDateFormat);
  const color = useSelector(getAppColor);
  const timeCalendars = useSelector(getTimeCalendars);
  const unAuthArray = useSelector(getUnAuthArray);

  const gridRef = useRef();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getExtraHoursAuthorizationFromServer());
  }, [dispatch])

  const selectCalendar = e => {
    const calendarElement = timeCalendars.find(el => el.key === e);
    dispatch(getExtraHoursAuthorizationFromServer(
      moment(calendarElement.from, "DD/MM/YYYY").format("YYYY-MM-DD"),
      moment(calendarElement.to, "DD/MM/YYYY").format("YYYY-MM-DD")
    )).then(resp => {
      if (resp.statusText === "OK") {
        setSelectedCalendar(e);
        setGridData(null);
      }
    });
  }

  useEffect(() => {
    if (selectedCalendar !== "" && gridRef) {
      setGridReference(gridRef.current.refs.dataGrid._instance);
    }
  }, [gridRef, selectedCalendar])

  const isZero = value => {
    return value === "" || value === null || value === undefined || value === "0" || value === 0.00 || value === 0;
  }

  const showConfirmClearFilterData = (isSave, isColaboratorDialog) => {
    dispatch(openDialogAction({
      msg: `Para ${isSave ? "guardar información" : "agregar colaboradores"}, los filtros aplicados serán desactivados. Posteriormente podrá activarlos nuevamente.`,
      title: "Confirmación",
      onConfirm: () => clearFilterData(isSave, isColaboratorDialog),
      acceptOnly: false
    }))
  }

  const clearFilterData = (isSave, isColaboratorDialog) => {
    setFilterData(filtersDummyData);
    let ds = gridReference.getDataSource();
    let filter = gridReference.getCombinedFilter();

    gridReference.saveEditData().done(() => {
      ds.store().load({ sort: ds.sort(), filter: filter ? filter : null })
        .done((allData) => {
          setShouldReloadGrid(true);
          setGridData(getGridData(allData));
          if (isSave) {
            handleSave();
          } else {
            if (isColaboratorDialog) {
              setUserSelectionDialogIsOpen(true);
            } else {
              setSecondGridDialogIsOpen(true);
            }
          }
        })
    });
  }

  const handleSave = () => {
    let hasError = "";
    let hasDuplicates = "";
    let ds = gridReference.getDataSource();
    let filter = gridReference.getCombinedFilter();
    const calendarElement = timeCalendars.find(el => el.key === selectedCalendar);

    gridReference.saveEditData().done(() => {
      ds.store().load({ sort: ds.sort(), filter: filter ? filter : null })
        .done((allData) => {
          const rowData = allData;
          gridReference.getController("validating").validate(true).then(isValid => {
            if (isValid) {
              let filteredData = rowData.filter(row => !isZero(row["Hrs Extras Autorizadas"]));
              let formattedData = filteredData.map(row => {
                let modifiedRow = { ...row };

                if (row["Fecha"]) {
                  modifiedRow["Fecha"] = formatDate(row["Fecha"]);
                }

                let valueModified = false;
                for (let [key, value] of Object.entries(modifiedRow)) {
                  if (key !== "Apellido y nombres" && key !== "Código de empleado") {
                    if (key !== "Fecha" && value) {
                      if (key.includes("Hrs ")) {
                        let newKey = key.replace("Hrs ", "Hrs. ");
                        delete modifiedRow[key];
                        modifiedRow[newKey] = value;
                        valueModified = true;
                      }
                    }
                  }
                }
                if (valueModified && row["Fecha"] === undefined) {
                  hasError = "Verifique que las celdas de Fecha esten completas";
                }

                return modifiedRow;
              });


              let arrCheckDuplicatedElements = [];
              formattedData.forEach(data => {
                const duplicated = arrCheckDuplicatedElements.find(el => el.date === data["Fecha"] && el.name === data["Código de empleado"]);
                if (duplicated) {
                  hasDuplicates = `Verifique que no haya más de un registro con la misma fecha para un colaborador (${duplicated.name})`;
                } else {
                  arrCheckDuplicatedElements.push({ name: data["Código de empleado"], date: data["Fecha"] });
                }
              });

              if (hasError) {
                dispatch(openSnackbarAction({ msg: hasError, severity: "error" }));
                return;
              }

              if (hasDuplicates) {
                dispatch(openSnackbarAction({ msg: hasDuplicates, severity: "error" }));
                return;
              }
              try {
                dispatch(setExtraHoursAuthorizationToServer(
                  {
                    from: moment(calendarElement.from, "DD/MM/YYYY").format("YYYY-MM-DD"),
                    to: moment(calendarElement.to, "DD/MM/YYYY").format("YYYY-MM-DD"),
                    items: formattedData
                  }
                ))
                  .then((resp) => {
                    if (resp?.status === 200) {
                      //limpiar el seleccionado
                      gridReference.deselectAll()

                      setGridData(null);
                    }
                  })
              } catch (e) {
                dispatch(openSnackbarAction({ msg: "Ocurrió un error en el servidor", severity: "error" }));

              }
            } else {
              dispatch(openSnackbarAction({ msg: "Verifique que las celdas tengan valores válidos", severity: "error" }));
            }
          })
        });
    })
  };

  const getMinutesForHHMM = hhmm => {
    const timeArray = hhmm.split(':');
    if (timeArray.length === 0) {
      return 0;
    }

    const minutes = (+timeArray[0]) * 60 + (+timeArray[1]);
    return minutes;
  }

  const getHHMMforMinutes = time => {
    if (!time) {
      return "00:00";
    }

    const hours = Math.floor(parseInt(time) / 60);
    const minutes = parseInt(time) % 60;

    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
  }

  const handleOpenDeleteDialog = () => {
    // Me fijo cuantas filas seleccionadas tengo
    let selectedRows = gridReference.getSelectedRowsData();

    // Si no hay seleccionado, pido seleccionar
    if (selectedRows.length !== 1) {
      dispatch(openSnackbarAction({ msg: "Debe seleccionar una fila", severity: "warning" }));
      return;
    }

    if (selectedRows.some(el => el["Hrs Extras Sin Autorizar"] === "00:00" && el["Hrs Extras Realizadas"] === "00:00")) {
      dispatch(openDialogAction({
        title: getLocalizedString("atention"),
        msg: '¿Desea eliminar las filas seleccionadas?',
        onConfirm: () => deleteRowsLocally(selectedRows),
      }));
    } else {
      dispatch(openSnackbarAction({ msg: 'Solo es posible eliminar el registro cuando "No existan" horas en "Horas extras Sin Autorizar", si desea no autorizar ninguna, ingrese 00:00 en "Horas Extras Autorizadas"', severity: "error", duration: null }));
      return;
    }
  }

  const handleOpenCalendarDialog = () => {
    // Me fijo cuantas filas seleccionadas tengo
    let selectedRows = gridReference.getSelectedRowsData();

    // Si no hay seleccionado, pido seleccionar
    if (selectedRows.length !== 1) {
      dispatch(openSnackbarAction({ msg: "Debe seleccionar una fila", severity: "warning" }));
      return;
    }

    const stateToSend = { date: moment(selectedRows[0]['Fecha']).format("DD/MM/YYYY"), code: selectedRows[0]['Código de empleado'], from: paths.extraHoursAuthorization };

    history.push({
      pathname: paths.myPeopleSchedule,
      state: stateToSend,
    });
  }

  const deleteRowsLocally = (rowsToDelete) => {
    gridReference.cancelEditData();
    if (Array.isArray(rowsToDelete)) {
      const ds = gridReference.getDataSource();
      const filter = gridReference.getCombinedFilter();
      ds.store().load({ sort: ds.sort(), filter: filter ? filter : null })
        .done((allData) => {
          const rowData = allData;
          rowsToDelete.forEach(row => {
            const filteredRows = rowData.filter(el => JSON.stringify(el) === JSON.stringify(row));
            filteredRows.forEach(gridRow =>
              gridReference.getDataSource().store().remove(gridRow).then(() => {
                gridReference.refresh();
              })
            )
          });
        });
    }
    dispatch(openSnackbarAction({ msg: 'Valores eliminados con éxito', severity: "success" }));
  }

  const handleGoBack = () => {
    history.push(paths.assetsAndDiscountsScreenSelector);
  }

  const handleExportStructure = () => {
    if (selectedCalendar === "") {
      dispatch(openSnackbarAction({ msg: "Debe seleccionar un calendario", severity: "warning" }));
      return;
    }
    const calendarElement = timeCalendars.find(el => el.key === selectedCalendar);
    dispatch(downloadImportStructureAction({
      calendarType: calendarElement.type,
      from: moment(calendarElement.from, "DD/MM/YYYY").format("YYYY-MM-DD"),
      to: moment(calendarElement.to, "DD/MM/YYYY").format("YYYY-MM-DD"),
      empty: false
    }));
  }

  const handleExportGrid = () => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Autorización de horas extras');

    exportDataGrid({
      component: gridReference,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Autorizacion de horas extras.xlsx');
      });
    });
  }

  const saveItem = () => (
    <CustomIconButton
      title={"Guardar"}
      onClick={hasFilterData ? () => showConfirmClearFilterData(true) : handleSave}
      type={"save"}
    />
  )

  const deleteItem = () => (
    <CustomIconButton
      title={"Eliminar"}
      onClick={handleOpenDeleteDialog}
      type={"delete"}
    />
  )

  const calendarItem = () => (
    <CustomIconButton
      title={"Ver calendario"}
      onClick={handleOpenCalendarDialog}
      type={"calendar"}
    />
  )

  const exportGrid = () => (
    <CustomIconButton
      title={"Exportar datos"}
      onClick={handleExportGrid}
      type={"excel"}
    />
  )

  const exportStructureButton = () => (
    <CustomIconButton
      title={"Exportar estructura de importación"}
      onClick={handleExportStructure}
      type={"download"}
    />
  )

  const handleReloadGrid = () => {
    const calendarElement = timeCalendars.find(el => el.key === selectedCalendar);
    dispatch(getExtraHoursAuthorizationFromServer(
      moment(calendarElement.from, "DD/MM/YYYY").format("YYYY-MM-DD"),
      moment(calendarElement.to, "DD/MM/YYYY").format("YYYY-MM-DD")
    )).then(resp => {
      if (resp.statusText === "OK") {
        setGridData(null);
      }
    });
  }

  const importStructureButton = () => (
    <CustomIconButton
      title={"Importar autorización de horas extras"}
      onClick={() => setUploadFileDialogIsOpen(true)}
      type={"upload"}
    />
  )


  const getGridData = useCallback((arrayToMerge) => {
    let formattedData = rowData.map(originalRow => {
      let row = { ...originalRow };

      for (let [key, value] of Object.entries(row)) {
        if (key.includes("Hrs.")) {
          delete row[key];
          let newKey = key.replace("Hrs.", "Hrs");
          row[newKey] = value;
        }
      }

      row["Fecha"] = moment(row["Fecha"], "DD/MM/YYYY");
      return row;
    });

    if (arrayToMerge && arrayToMerge.length > 0) {
      arrayToMerge.forEach(mergeElement => {
        const oldElementIndex = formattedData.findIndex(el =>
          moment(el["Fecha"]).format("DD/MM/YYYY") === moment(mergeElement["Fecha"]).format("DD/MM/YYYY") &&
          el["Código de empleado"] === mergeElement["Código de empleado"]
        );

        if (oldElementIndex !== -1) {
          formattedData[oldElementIndex] = { ...mergeElement, "Fecha": moment(mergeElement["Fecha"]).format("DD/MM/YYYY") };
        }
      })
    }
    return formattedData;
  }, [rowData]);

  // Apenas tenga valores, voy a cargar la data en la grilla
  useEffect(() => {
    if (!isLoading && !gridData && selectedCalendar && rowData) {
      setGridData(getGridData());
      setShouldReloadGrid(true);
    }
  }, [getGridData, isLoading, gridData, rowData, selectedCalendar]);

  const updateFilteredDataObject = (filterData) => {
    let filteredData = [...gridData];
    let filteredColaborators = [...colaborators];

    // Primero filtro por colaborador, es el más rapido
    if (filterData["Colaborador"]) {
      filteredColaborators = filteredColaborators.filter(row => row["CODIGO DE EMPLEADO"] === filterData["Colaborador"].key)
    }

    if (filterData["Horario"]) {
      const horarioElement = selects["Horario"].find(el => el.key === filterData["Horario"]);
      if (horarioElement) {
        filteredColaborators = filteredColaborators.filter(row => row["HORARIO"] === `${horarioElement.key} - ${horarioElement.value}`)
      }
    }

    if (filterData["Turno"]) {
      const turnoElement = selects["Turno"].find(el => el.key === filterData["Turno"]);
      if (turnoElement) {
        filteredColaborators = filteredColaborators.filter(row => row["TURNO"] === turnoElement.value)
      }
    }

    if (filterData["Lugar de trabajo"]) {
      const lugarDeTrabajoElement = selects["Lugar de trabajo"].find(el => el.key === filterData["Lugar de trabajo"]);
      if (lugarDeTrabajoElement) {
        filteredColaborators = filteredColaborators.filter(row => row["LUGAR DE TRABAJO"] === lugarDeTrabajoElement.value)
      }
    }

    if (filterData["Obra o Faena"]) {
      const obraOFaenaElement = selects["Obra o Faena"].find(el => el.key === filterData["Obra o Faena"]);
      if (obraOFaenaElement) {
        filteredColaborators = filteredColaborators.filter(row => row["OBRA O FAENA"] === obraOFaenaElement.value)
      }
    }

    if (filterData["Gerencia"]) {
      const gerenciaElement = selects["Gerencia"].find(el => el.key === filterData["Gerencia"]);
      if (gerenciaElement) {
        filteredColaborators = filteredColaborators.filter(row => row["GERENCIA"] === gerenciaElement.value)
      }
    }

    filteredColaborators = filteredColaborators.map(el => el["CODIGO DE EMPLEADO"]);

    // Solo voy a mostrar la data que corresponda a los colaboradores que cumplen con el filtro
    filteredData = filteredData.filter(row => filteredColaborators.includes(row["Código de empleado"]));

    if (filterData["Solo horas extras"] && filterData["Solo horas extras"] === "extra") {
      filteredData = filteredData.filter(row => !isZero(row["Hrs Extras Sin Autorizar"]))
    }

    setFilteredData(filteredData);
    setShouldReloadGrid(true);
    setFiltersDialogIsOpen(false);
  }

  const getColaboratorData = employeeCode => { return colaborators.find(colaborator => colaborator["CODIGO DE EMPLEADO"] === employeeCode) }
  const getUnAuthData = (date, employeeCode) => {
    return unAuthArray.find(el => el.resource === employeeCode &&
      moment(date).format("DD/MM/YYYY") === el["Fecha"])
  }
  const generateColumns = () => {
    // Primero, tengo las columnas fijas
    let columns = [];
    columns.push({
      headerName: "Código de empleado",
      field: "Código de empleado",
      isObligatory: true,
      disabled: true,
      maxLength: 254,
      minLength: 0,
      isFixed: true,
    });

    columns.push({
      headerName: "Apellido y nombres",
      field: "Apellido y nombres",
      isObligatory: true,
      maxLength: 254,
      minLength: 0,
      isFixed: true,
      disabled: true
    });

    columns.push({
      headerName: "Fecha",
      field: "Fecha",
      cellEditor: "dateEditor",
      filter: "agTextColumnFilter",
      isDate: true,
      dataType: "date",
      isObligatory: true,
    });

    columns.push({
      headerName: "Hrs. Extras Autorizadas",
      field: "Hrs Extras Autorizadas",
      maxLength: 5,
      dataType: "string",
    });

    columns.push({
      headerName: "Hrs. Extras Realizadas",
      field: "Hrs Extras Realizadas",
      maxLength: 5,
      dataType: "string",
      disabled: true
    });

    columns.push({
      headerName: "Hrs. Extras Sin Autorizar",
      field: "Hrs Extras Sin Autorizar",
      maxLength: 5,
      dataType: "string",
      disabled: true
    });

    return columns;
  }

  const handleSetUsers = (values, replicate) => {

    // Voy a omitir los que ya estan en la grilla
    let ds = gridReference.getDataSource();
    let filter = gridReference.getCombinedFilter();

    ds.store().load({ sort: ds.sort(), filter: filter ? filter : null })
      .done((allData) => {
        values.forEach(colab => {
          if (!replicate || (!allData.some(el => el["Código de empleado"] === colab &&
            moment(el["Fecha"]).format("DD/MM/YYYY") === moment(rowDataToReplicate[0]["Fecha"]).format("DD/MM/YYYY")))) {
            const fullColaboradorData = colaborators.find(colaborator => colaborator["CODIGO DE EMPLEADO"] === colab);

            const dataSource = gridReference.getDataSource();
            let data = {};
            if (replicate) {
              data = { ...rowDataToReplicate[0], "Hrs Extras Realizadas": "00:00", "Hrs Extras Sin Autorizar": "00:00" }
            }

            data["Código de empleado"] = fullColaboradorData["CODIGO DE EMPLEADO"];
            data["Apellido y nombres"] = fullColaboradorData["APELLIDO Y NOMBRE"];


            dataSource.store().insert(data).then(function () {
              dataSource.reload();
            })
          }
        });
      })
  }



  const hasFilterData = Object.keys(filterData).some(item => filterData[item] !== "");

  const filterItem = () =>
    <CustomIconButton
      title={"Filtrar"}
      onClick={() => setFiltersDialogIsOpen(true)}
      type={"filter"}
      isSelected={hasFilterData}
    />

  let customItems = [filterItem, saveItem, exportStructureButton, importStructureButton, exportGrid, calendarItem, deleteItem]


  const openAddColaboratorDialog = () => {
    if (hasFilterData) {
      showConfirmClearFilterData(false, true);
    } else {
      setUserSelectionDialogIsOpen(true);
    }
  }
  const openAddListColaboratorsDialog = () => {
    if (hasFilterData) {
      showConfirmClearFilterData(false, false);
    } else {
      setSecondGridDialogIsOpen(true);
    }
  }

  const copyDataToReplicate = () => {
    if (rowDataToReplicate) {
      setRowDataToReplicate(null);
    } else {
      // Me fijo cuantas filas seleccionadas tengo
      let selectedRows = gridReference.getSelectedRowsData();

      // Si no hay seleccionado, pido seleccionar
      if (selectedRows.length === 0) {
        dispatch(openSnackbarAction({ msg: "Debe seleccionar una fila", severity: "warning" }));
        return;
      } else if (selectedRows.length > 1) {
        dispatch(openSnackbarAction({ msg: "Debe seleccionar una única fila", severity: "warning" }));
        return;
      }
      gridReference.saveEditData().done(resp => setRowDataToReplicate(selectedRows))

    }
  }

  return (
    <CommonPage
      title={'Autorización de horas extras'}
      menuItems={selectedCalendar !== "" ? customItems : []}
      isLoading={isLoading}
      isNotGridPage
    >
      <Grid container item alignItems="center" style={{ padding: "0px 24px" }} xs={12}>
        <Grid container item xs={12}>
          <Grid container item xs={4}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel required id={`label-calendar`}>{"Calendario de tiempos"}</InputLabel>
              <Select
                required
                value={selectedCalendar}
                labelId={`label-calendar`}
                id={`select-calendar`}
                onChange={(e) => selectCalendar(e.target.value)}
                margin={"none"}
              >
                {
                  timeCalendars && timeCalendars.map(item => (
                    <MenuItem
                      className={"whiteText"}
                      key={item.key}
                      value={item.key}>
                      {item.value}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Grid>
          {
            selectedCalendar !== "" &&
            <Grid container item xs={8} justify="flex-end" >
              <Button
                startIcon={<FileCopyIcon htmlColor={rowDataToReplicate ? "#FFFFFF" : color} />}
                className="whiteText"
                onClick={copyDataToReplicate}
                variant='contained'
                style={{
                  marginRight: 10,
                  backgroundColor: rowDataToReplicate ? 'red' : 'var(--secondaryBackgroundColor)',
                  border: '1px solid var(--listAnnotation)'
                }}
              >
                {rowDataToReplicate ? "Limpiar los datos copiados" : "Copiar datos para replicar"}
              </Button>
              <Button
                startIcon={<AddIcon htmlColor={color} />}
                className="whiteText"
                onClick={openAddColaboratorDialog}
                variant='contained'
                style={{
                  marginRight: 10,
                  backgroundColor: 'var(--secondaryBackgroundColor)',
                  border: '1px solid var(--listAnnotation)',
                }}
              >
                {"Colaborador"}
              </Button>
              <Button
                startIcon={<AddIcon htmlColor={color} />}
                className="whiteText"
                onClick={openAddListColaboratorsDialog}
                variant='contained'
                style={{
                  backgroundColor: 'var(--secondaryBackgroundColor)',
                  border: '1px solid var(--listAnnotation)'
                }}
              >
                {"Lista de colaboradores"}
              </Button>
            </Grid>
          }
        </Grid>
      </Grid>
      {
        selectedCalendar !== "" &&
        <EditableGridv2
          setShouldReloadGrid={setShouldReloadGrid}
          shouldReloadGrid={shouldReloadGrid}
          setDataChanged={() => null}
          ref={gridRef}
          gridName="extraHoursAuthorization"
          rowData={hasFilterData ? filteredData : gridData}
          columns={generateColumns()}
          getColaboratorData={getColaboratorData}
          batchMode
          getHHMMforMinutes={getHHMMforMinutes}
          getMinutesForHHMM={getMinutesForHHMM}
          getUnAuthData={getUnAuthData}
        />
      }
      {
        confirmationDialogIsOpen &&
        <ConfirmationDialog
          open={confirmationDialogIsOpen}
          handleConfirm={handleGoBack}
          handleClose={() => setConfirmationDialogIsOpen(false)}
        />
      }
      {
        userSelectionDialogIsOpen &&
        <UserSelectionDialogWithReplica
          open={userSelectionDialogIsOpen}
          users={societyPeople}
          title={"Agregar colaborador"}
          selectedUsersTitle={"Colaboradores seleccionados"}
          isDataReplicateDisabled={rowDataToReplicate === null}
          handleSubmit={(values, replicate) => handleSetUsers(values, replicate)}
          handleClose={() => setUserSelectionDialogIsOpen(false)}
        />
      }
      {
        secondGridDialogIsOpen && !isLoading &&
        <SecondGridDialog
          open={secondGridDialogIsOpen}
          data={colaborators}
          dateFormat={dateFormat}
          locale={locale}
          isDataReplicateDisabled={rowDataToReplicate === null}
          handleConfirm={handleSetUsers}
          handleClose={() => setSecondGridDialogIsOpen(false)}
        />
      }
      {
        !isLoading && filtersDialogIsOpen &&
        <FiltersDialog
          open={filtersDialogIsOpen}
          data={filterData}
          setFilterData={setFilterData}
          handleClose={() => setFiltersDialogIsOpen(false)}
          handleConfirm={updateFilteredDataObject}
        />
      }
      {
        selectedCalendar === "" &&
        <Grid container item xs={12} style={{ margin: "10px 0px" }}>
          <Grid container alignItems="center" justify="center" direction="column" style={{ marginTop: 150 }}>
            <Typography color="textSecondary" className="whiteText" variant="h2" style={{ fontSize: 32, fontWeight: 700, marginBottom: 16 }}>
              {"Seleccione un calendario de tiempo"}
            </Typography>
          </Grid>
        </Grid>
      }
      {
        uploadFileDialogIsOpen &&
        <UploadFileDialog
          open={uploadFileDialogIsOpen}
          reloadGrid={handleReloadGrid}
          isLoading={isLoading}
          handleClose={() => setUploadFileDialogIsOpen(false)}
        />
      }
    </CommonPage>
  );
}

export default ExtraHoursAuthorization;
