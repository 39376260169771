import React, { useState } from "react";
import { useSelector } from "react-redux";
import { getSelects } from "../selectors";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  TextField,
} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import PaperDraggable from "@icarius-common/paperDraggable";
import { CloseIcon } from "@icarius-icons";
import { getLocalizedString } from "@icarius-localization/strings";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import DialogTransition from "@icarius-common/dialogTransition";

const gridStyle = { height: 75, padding: "0px 10px" };

const FilterDialog = (props) => {
  const { open, data, handleClose, handleConfirm, setFilterData } = props;
  const [filterInternalData, setFilterInternalData] = useState(data);

  const filters = useSelector(getSelects);

  const getLabel = (name) => {
    return getLocalizedString(`filter${name}`);
  }

  const handleChange = (name, value) => {
    setFilterInternalData({ ...filterInternalData, [name]: value });
  }

  const validateAndCreate = () => {
    setFilterData(filterInternalData);
    handleConfirm(filterInternalData);
    handleClose();
  }

  return (
    <Dialog
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      open={open}
      maxWidth={"sm"}
      fullWidth={true}
    >
      <div className={"dialog-container"}>
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {"Filtros"}
          <DialogTitleDivider />
        </DialogTitle>
        <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
        <DialogContent style={{ paddingTop: 0 }}>
          <Grid container item xs={12}>
            <Grid container item alignItems="center" xs={12} style={gridStyle}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel shrink id={`label-turn`}>{"Horas extras"}</InputLabel>
                <Select
                  value={filterInternalData["Solo horas extras"]}
                  labelId={`label-turn`}
                  id={`select-turn`}
                  onChange={(e) => handleChange("Solo horas extras", e.target.value)}
                  margin={"none"}
                  displayEmpty
                >
                  <MenuItem className={"whiteText"} value={''}>{'Todos'}</MenuItem>
                  <MenuItem className={"whiteText"} value={'extra'}>{'Solo con horas extras por autorizar'}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid container item xs={12}>
              {
                filters && Object.values(filters).map((filter, index) => {
                  const name = Object.keys(filters)[index];
                  if (name === "Colaborador") {
                    return <div key={index}></div>
                  }
                  return (
                    <Grid key={index} container item alignItems="center" xs={12} style={gridStyle}>
                      <FormControl style={{ width: "100%" }}>
                        <InputLabel shrink id={`label-turn`}>{getLabel(name)}</InputLabel>
                        <Select
                          value={filterInternalData[name]}
                          labelId={`label-${name}`}
                          id={`select-${name}`}
                          onChange={(e) => handleChange(name, String(e.target.value))}
                          margin={"none"}
                          displayEmpty
                        >
                          <MenuItem className={"whiteText"} value={''}>
                            {'Sin filtro'}
                          </MenuItem>
                          {
                            filter.map((item) => (
                              <MenuItem
                                className={"whiteText"}
                                key={item.key}
                                value={item.key}>
                                {item.value}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  )
                })
              }
            </Grid>
            <Grid container item alignItems="center" xs={12} style={gridStyle}>
              <Autocomplete
                style={{ width: '100%' }}
                options={filters["Colaborador"]}
                value={filterInternalData["Colaborador"]}
                getOptionSelected={(selected) => Boolean(filters["Colaborador"].find(user => user.key === selected.key) !== undefined)}
                onChange={(event, newUser) => {
                  handleChange("Colaborador", newUser || "");
                }}
                clearOnBlur
                clearText={getLocalizedString("clear")}
                noOptionsText={''}
                getOptionLabel={(user) => user.value || ""}
                renderInput={(params) => <TextField {...params} label={'Colaborador'} />}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <ButtonDialogAction onClick={handleClose} text={getLocalizedString("disagree")} />
          <ButtonDialogAction type="submit" onClick={validateAndCreate} isAccept text={getLocalizedString("agree")} />
        </DialogActions>
      </div>
    </Dialog>
  )
}

export default FilterDialog;