import * as actionTypes from "./actionTypes";

const initialState = {
  loading: false,
  societyPeople: [],
  colaborators: [],
  timeCalendars: [],
  selects: {},
  dateFormat: "dd/mm/yyyy",
  currencyLocalization: "da",
  rows: null,
  unAuthArray: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_ROWS:
      return { ...state, loading: true, rows: null, unAuthArray: [] };
    case actionTypes.GET_ROWS_FULFILLED:
      return {
        ...state,
        loading: false,
        rows: action.payload.rows,
        colaborators: action.payload.colaborators,
        currencyLocalization: action.payload.currency_localization,
        dateFormat: action.payload.date_format,
        selects: action.payload.selects,
        societyPeople: action.payload.societyPeople,
        timeCalendars: action.payload.timeCalendars,
        unAuthArray: action.payload.unAuthArray,
      };
    case actionTypes.GET_ROWS_REJECTED:
      return { ...state, loading: false };

    case actionTypes.SET_ROWS:
      return { ...state, loading: true };
    case actionTypes.SET_ROWS_FULFILLED:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.SET_ROWS_REJECTED:
      return { ...state, loading: false };

    case actionTypes.DOWNLOAD:
      return { ...state, loading: true };
    case actionTypes.DOWNLOAD_FULFILLED:
      return { ...state, loading: false };
    case actionTypes.DOWNLOAD_REJECTED:
      return { ...state, loading: false };

    default:
      return state;
  }
}
